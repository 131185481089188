import { buildLibraryList, LibraryCardRow } from '@front/shared/ds';
import { Section } from '@shared/master-types';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';

import useFetchNews from '../../../hooks/useWPposts';
import { useAppDispatch } from '../../../store/store.hooks';
import toCardButtons from '../../../utils/toCardButtons';

export type TLibraryCardRowWpConnected = Extract<
  Required<Section>['rows'][number],
  { blockType: 'library-card-row-wp' }
>;

const LibraryCardRowWpConnected: React.FC<
  TLibraryCardRowWpConnected
> = props => {
  const { newsApiDomain, newsApiEndpoint, allTabTitle, buttons } = props;

  const router = useRouter();
  const appDispatch = useAppDispatch();
  const currentLocale = String(router.query.locale || '');

  const { newsPosts, error, loading } = useFetchNews(
    newsApiDomain,
    newsApiEndpoint,
    false,
  );

  const { tabTitles, tabPosts } = useMemo(
    () => buildLibraryList(newsPosts, allTabTitle),
    [allTabTitle, newsPosts],
  );

  if (error) {
    return null;
  }

  return (
    <LibraryCardRow
      buttons={toCardButtons(buttons, currentLocale, appDispatch)}
      tabTitles={tabTitles}
      isLoading={loading}
      tabPosts={tabPosts}
    />
  );
};

export default LibraryCardRowWpConnected;
