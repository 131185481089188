import React from 'react';

import { ESolidTabSize, ESolidTabTheme, SolidTab } from '../../atoms/SolidTab';

type TTabsButtonsProps = {
  tabs: { title: string }[];
  value: number;
  onChange: (index: number) => void;
};

export const TabsButtons: React.FC<TTabsButtonsProps> = ({
  tabs,
  value,
  onChange,
}) => {
  return (
    <div className='flex w-fit justify-center gap-y-4 xl:flex xl:flex-wrap xl:px-4'>
      {tabs.map((tabItem, i) => (
        <SolidTab
          key={i}
          onClick={() => onChange(i)}
          label={tabItem.title}
          value={tabItem.title}
          size={ESolidTabSize.Medium}
          theme={ESolidTabTheme.Ordinary}
          isActive={i === value}
        />
      ))}
    </div>
  );
};
