import React from 'react';

import { ReactComponent as IconDropDown } from '../../../../../ds/public/icons/icon-arrow-down.svg';

type TTabsSelectProps = {
  tabs: { title: string }[];
  value: number;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

export const TabsSelect: React.FC<TTabsSelectProps> = ({
  tabs,
  value,
  onChange,
}) => {
  return (
    <div className='relative mx-auto w-full'>
      <IconDropDown className='pointer-events-none absolute end-[1.25rem] top-1/2 -translate-y-1/2' />
      <select
        value={value}
        onChange={onChange}
        className='w-full appearance-none whitespace-pre-wrap rounded-[2rem] border-2 border-interface-1000 px-5 py-[0.95rem] pe-10 ps-6 text-base font-medium leading-loose text-interface-1000 outline-none'
      >
        {tabs.map((tabItem, i) => (
          <option key={i} value={i}>
            {tabItem.title}
          </option>
        ))}
      </select>
    </div>
  );
};
