import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';

import { TabBody } from './TabBody';
import { TabsButtons } from './TabsButtons';
import { TabsSelect } from './TabsSelect';
import { ReactComponent as LoaderImg } from '../../../../public/icons/loaderAnimation.svg';
import Button from '../../atoms/Button/Button';
import { ELibraryCardType } from '../../atoms/LibraryCard';
import { TNewsCardProps } from '../../atoms/NewsCard/NewsCard';
import Container from '../../sections/Container/Container';

type TLibraryCardRow = {
  tabTitles: Record<ELibraryCardType, string>;
  tabPosts: { title: string; posts: TNewsCardProps[] }[];
  isLoading?: boolean;
  buttons?: React.ComponentProps<typeof Button>[];
};

export const LibraryCardRow: React.FC<TLibraryCardRow> = ({
  tabTitles,
  tabPosts,
  isLoading,
  buttons,
}) => {
  const [tab, setTab] = useState(0);
  const [containerWidth, setContainerWidth] = useState<number | null>(null);
  const [tabsButtonsWidth, setTabsButtonsWidth] = useState<number | null>(null);
  const [isTabsButtonsWider, setIsTabsButtonsWider] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const tabsButtonsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isLoading) {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
      if (tabsButtonsRef.current) {
        setTabsButtonsWidth(tabsButtonsRef.current.offsetWidth);
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (containerWidth !== null && tabsButtonsWidth !== null) {
      setIsTabsButtonsWider(tabsButtonsWidth > containerWidth);
    }
  }, [containerWidth, tabsButtonsWidth]);

  const handleSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    setTab(parseInt(e.target.value, 10));
  };

  const handleButtonClick = (index: number): void => {
    setTab(index);
  };

  const tabsTitles = Object.values(tabTitles).map(title => ({ title }));

  const currentTabPosts = tabPosts[tab]?.posts || [];

  return (
    <section>
      <Container>
        <div ref={containerRef}>
          {isLoading ? (
            <div className='h-full w-full bg-surface-100'>
              <LoaderImg className='h-[1280px] w-full tablet:h-[670px]' />
            </div>
          ) : (
            <>
              <div className='mb-6 overflow-x-hidden'>
                <div
                  ref={tabsButtonsRef}
                  className={clsx(
                    'w-fit',
                    isTabsButtonsWider ? 'hidden' : 'mx-auto block',
                  )}
                >
                  <TabsButtons
                    tabs={tabsTitles}
                    value={tab}
                    onChange={handleButtonClick}
                  />
                </div>
                <div className={clsx(isTabsButtonsWider ? 'block' : 'hidden')}>
                  <TabsSelect
                    tabs={tabsTitles}
                    value={tab}
                    onChange={handleSelectChange}
                  />
                </div>
              </div>
              <TabBody buttons={buttons} tabPosts={currentTabPosts} />
            </>
          )}
        </div>
      </Container>
    </section>
  );
};
